<template>
  <span
    v-if="tag === 'span'"
    :class="{
      'bard [&_a]:font-bold [&_a]:underline': !unstyled,
    }"
    @click="onClick"
    v-html="content"
  />
  <div
    v-else
    :class="{
      'bard [&_a]:font-bold [&_a]:underline text-base': !unstyled,
    }"
    @click.prevent="onClick"
    v-html="content"
  />
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    content: string | undefined
    unstyled?: boolean
    tag?: 'div' | 'span'
  }>(),
  {
    unstyled: false,
    tag: 'div',
  }
)

const router = useRouter()
const runtimeConfig = useRuntimeConfig()

const onClick = async (event: MouseEvent) => {
  const target = event.target as HTMLLinkElement | null
  if (!process.client || !target) {
    return null
  }
  if (target.parentElement?.tagName === 'LABEL') {
    return null
  }
  event.preventDefault()
  if (target?.tagName?.toUpperCase() === 'A') {
    if (target.target === '_blank') {
      return window.open(target.href, '_blank')
    }

    if (target.href.startsWith(runtimeConfig.public.APP_URL)) {
      return await router.push(
        target.href.replace(runtimeConfig.public.APP_URL, '')
      )
    }

    if (!target.href.match(/^(http|https):\/\//)) {
      return await router.push(target.href)
    }

    return window.open(target.href, '_self')
  }
}
</script>
